@import './assets/styles/addon/main';

#root {
  margin: auto;

  @media screen and (min-height: $break-point-tablet) {
    max-width: $break-point-tablet;
  }
}

.Toastify__toast--info {
  background-color: $color-blue;
}

.Toastify__toast--success {
  background-color: $color-green;
}

.Toastify__toast--warning {
  background-color: $color-yellow;
}

.Toastify__toast--error {
  background-color: $color-pink;
}

.Toastify__toast {
  min-height: px2rem(57);
  padding: px2rem(16) px2rem(25);
  border-radius: px2rem(5);
  box-shadow: 0 px2rem(2) px2rem(24) 0 rgba(0, 0, 0, 0.08);
  border: solid px2rem(1) rgba(0, 0, 0, 0.1);
}

.Toastify__toast-body {
  font-size: px2rem(18);
  line-height: px2rem(25);
  margin: 0;
}
