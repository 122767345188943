.loading-circle {
  position: relative;

  .loading-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    .loading-dot {
      content: '';
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      border-radius: 100%;
      animation: loadingCircleBounceDelay 1.2s infinite ease-in-out both;
    }
  }

  @for $i from 2 through 12 {
    .loading-circle#{$i} {
      // 30deg, 60deg, ..., 330deg
      transform: rotate(#{(($i - 1) * 30)}deg);

      .loading-dot {
        // -1.1s, -1s, -0.9s, ..., -0.1s
        animation-delay: #{(-1.3 + ($i * 0.1))}s;
      }
    }
  }
}

@keyframes loadingCircleBounceDelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
