@import '../../assets/styles/addon/main';

.modal-overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  @include scrollbar;

  .modal-center {
    min-height: calc(100% - 1.75rem * 2);
    max-width: 90vw;
    width: px2rem(480);
    margin: 1.75rem auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-content {
      width: 100%;
      background-color: $color-white;
      border: 0;
      border-radius: px2rem(4);

      &.modal-content-pulse {
        animation: modalPulse 0.1s;
      }
    }
  }
}

@keyframes modalPulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
