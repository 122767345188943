@import '../../assets/styles/addon/main';
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');

$member-card-max-width: px2rem(361);
$member-card-min-height: px2rem(213);

.member-card-wrapper {
  position: sticky;
  top: 0;
  width: 100%;
  margin-bottom: 1.625rem;
  z-index: 2;
}

.member-card-backdrop {
  background: linear-gradient(to bottom, #fff 90%, rgba(255, 255, 255, 0));
  height: 105%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.member-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: $member-card-max-width;
  min-height: $member-card-min-height;
  margin: auto;
  padding: px2rem(21) px2rem(21) px2rem(11);
  border-radius: px2rem(10);
  box-shadow: px2rem(2) px2rem(2) px2rem(8) 0 rgba($tundora, 0.5);
  position: relative;
  z-index: 1;

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .card-number {
      flex: 1;
      margin-right: px2rem(8);
    }

    .card-logo {
      width: px2rem(116);
      height: px2rem(34);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .card-footer {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .card-footer-links {
      align-items: center;
      display: flex;
    }
  }

  .card-content {
    align-items: center;
    display: flex;
  }
}
