@mixin scrollbar {
  // overflow: auto for Firefox
  overflow: auto;
  overflow: overlay;
  // For Firefox
  scrollbar-color: rgba(black, 0.3) transparent;
  // Scrollbar for webkit
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(black, 0);
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(black, 0.3);
    background-clip: padding-box;
    border-radius: 4px;
    border: solid 2px rgba(0, 0, 0, 0);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(black, 0.7);
    background-clip: padding-box;
    border-radius: 4px;
    border: solid 2px rgba(0, 0, 0, 0);
  }
}

@mixin dashboardContentBase {
  border: 0;
  border-radius: px2rem(5);
  box-shadow: 0 px2rem(2) px2rem(24) 0 $color-shadow;
  background-color: $color-white;
}
