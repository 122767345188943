html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-family: 'PingFang TC', 'Microsoft JhengHei', 'Microsoft YaHei',
    'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
small,
em,
strong,
ul,
li,
figure {
  margin: 0;
  padding: 0;
}

button {
  outline: none;
  cursor: pointer;
}

hr {
  border-top: solid 1px #ccc;
}

// Custom style
.text-icon-align-center {
  display: flex;
  align-items: center;
}

// react-tooltip custom style
.tooltip-default {
  max-width: px2rem(178) !important;
  padding: px2rem(8) px2rem(12) !important;
  font-size: px2rem(14) !important;
  line-height: px2rem(22) !important;
}

.table-default {
  border-collapse: collapse;
  width: 100%;
  font-size: px2rem(15);
  line-height: px2rem(22);
  text-align: left;
  color: $battleship-grey;

  .table-action-col {
    width: 1px; // Compression to minimum size
    padding: px2rem(16);

    .table-action-wrapper {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      .btn-action + .btn-action {
        margin-left: px2rem(20);
      }
    }
  }

  thead {
    background-color: $link-water;

    tr {
      border-bottom: solid px2rem(1) $mercury;
    }

    th {
      font-weight: 600;
      padding: px2rem(15) px2rem(16);
    }
  }

  tbody {
    tr {
      border-bottom: solid px2rem(1) $mercury;

      &:hover {
        background-color: $alabaster;
      }
    }

    td {
      padding: px2rem(19) px2rem(16);
    }
  }

  tfoot {
    background-color: $link-water;

    td {
      padding: px2rem(10) px2rem(16);
    }
  }

  .table-link {
    color: $cool-blue;
    font-size: px2rem(15);
    line-height: px2rem(18);
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;

    &.is-zero {
      text-decoration: none;
      cursor: default;
    }
  }
}

.escape-prefix::after {
  content: '{{';
}

.escape-suffix::after {
  content: '}}';
}

.portal {
  position: relative;
  z-index: 99;
}
